import React from 'react';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const AddBook = ({book, addBook}) => {

    const handleClick = () => {
        addBook(book);
    }

    return (
        <div className="add-book">
            <button onClick={handleClick} className="add-book-button">
                <PlaylistAddIcon className='icon'/>
                Add to Bookshelf
            </button>
        </div>
    );
}

export default AddBook;
