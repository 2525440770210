// Takes in a book object and creates links to Goodreads, Amazon, and Audible for the book.
// Later on, I will rebuild this to use my amazon affiliate links. For now, it just links to the search.
// amazon link directly to a page is https://www.amazon.com/dp/{id} - openlibrary API gives the ID!


import React from 'react';
import LinkCard from './LinkCard';

const BookLinks = ({ book }) => {

    const goodreadsLink = `https://www.goodreads.com/search?q=${book.title}`;
    const amazonLink = `https://www.amazon.com/s?k=${book.title + ' ' + book.author}`; // search for book on amazon
    const audibleLink = `https://www.audible.com/search?keywords=${book.title}`;
    const amazonReferral = book.amazon_link;

    const images = [
        'https://shrewsburypubliclibrary.org/wp-content/uploads/2021/10/GoodReads-Logo.png',
        'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Amazon_icon.svg/2048px-Amazon_icon.svg.png', 
        'https://images-na.ssl-images-amazon.com/images/G/01/audiblemobile/store/image/favicons/icons310px.png'
    ];

    return (
        <div className="book-links">
            <LinkCard link={goodreadsLink} logo={images[0]} style={{width:'65px'}}/>
            <LinkCard link={amazonReferral || amazonLink} logo={images[1]} style={{backgroundColor:'white', padding:'10px'}}/>
            <LinkCard link={audibleLink} logo={images[2]} />
        </div>
    );
}

export default BookLinks;