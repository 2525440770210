import supabase from '../resources/Supabase';

export const GetShelvesForOwner = async (owner) => {
    const { data, error } = await supabase
        .from('sections')
        .select('*, books(*, books_in_section(notes))')
        .eq('owner', owner);

    if (error) {
        console.error('Error fetching sections:', error.message);
        return;
    }

    // flatten the books_in_section array to just be the notes
    data.forEach(section => {
        section.books.forEach(book => {
            book.notes = book.books_in_section[0].notes;
            delete book.books_in_section;
        });
    });

    return data;
}

export const GetShelf = async (shelf) => {
    const { data, error } = await supabase
        .from('sections')
        .select('*, books(*, books_in_section(notes))')
        .eq('id', shelf);

    if (error) {
        console.error('Error fetching sections:', error.message);
        return;
    }

    // flatten the books_in_section array to just be the notes
    data.forEach(section => {
        section.books.forEach(book => {
            book.notes = book.books_in_section[0].notes;
            delete book.books_in_section;
        });
    });

    return data;
}