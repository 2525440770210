import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';  
import Search from '../components/Search';
import Book from '../components/Book';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useSwipeable } from 'react-swipeable';
import supabase from '../resources/Supabase';
import Modal from '../components/Modal';
import LoginForm from '../components/LoginForm';
import { useAuth } from '../resources/AuthContext';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CopyLink from '../components/CopyLink';

const ItemTypes = {
    BOOK: 'book',
};

const DraggableBook = ({ book, index, moveBook, deleteBook }) => {
    const [{ isDragging }, drag, preview] = useDrag({
        type: ItemTypes.BOOK,
        item: { type: ItemTypes.BOOK, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [showDelete, setShowDelete] = useState(false);

    const [, drop] = useDrop({
        accept: ItemTypes.BOOK,
        hover(item) {
            if (item.index !== index) {
                moveBook(item.index, index);
                item.index = index;
            }
        },
    });

    const handlers = useSwipeable({
        onSwipedLeft: () => setShowDelete(true),
        onSwipedRight: () => setShowDelete(false),
        trackMouse: true
    });

    return (
        <div className="swipeable"{...handlers}>
            <div ref={drop} className={`draggable-book ${isDragging ? 'dragging' : ''}`} >
                <Book book={book} showLinks={false} showAdd={false} />
                {!showDelete && (
                    <div ref={drag} className="drag-handle">
                        <DragIndicatorIcon />
                    </div>
                )}
                {showDelete && (
                    <button className="delete-button slide-in" onClick={() => deleteBook(book)}>
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
};

const CreateShelf = () => {
    const [initialBooks, setInitialBooks] = useState([]);
    const [books, setBooks] = useState([]);
    const [showFooter, setShowFooter] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const { userId } = useAuth();
    const [shelfId, setShelfId] = useState('');

    const add = (book) => {
        if (books.find((b) => b.key === book.key)) {
            return;
        }
        setBooks([...books, book]);
    };

    const deleteBook = (book) => {
        const updatedBooks = books.filter((b) => b.key !== book.key);
        setBooks(updatedBooks);
    };

    const moveBook = (fromIndex, toIndex) => {
        const updatedBooks = [...books];
        const [movedBook] = updatedBooks.splice(fromIndex, 1);
        updatedBooks.splice(toIndex, 0, movedBook);
        setBooks(updatedBooks);
    };

    const createShelf = async () => {
        // upsert to database
        const { data: shelfData, error: shelfError } = await supabase
            .from('sections')
            .insert({ owner: userId })
            .select();
        if (shelfError) {
            console.error('Error saving shelf:', shelfError.message);
            return null;
        }
        if (shelfData) {
            console.log('Shelf saved:', shelfData);
            return shelfData[0].id;
        }
    };

    const addBooksToShelf = async (shelfId, books) => {
        if (!shelfId) {
            console.error('No shelfId to add books to');
            return;
        }
        const booksInShelf = books.map((book) => ({
            book_id: book.id,
            section_id: shelfId,
        }));

        console.log('booksInShelf:', booksInShelf);
    
        const { data: booksInShelfData, error: booksInShelfError } = await supabase
            .from('books_in_section')
            .upsert(booksInShelf)
            .select();
        if (booksInShelfError) {
            console.error('Error saving books in shelf:', booksInShelfError.message);
            return;
        }
        if (booksInShelfData) {
            console.log('Books in shelf saved:', booksInShelfData);
        }
    };

    const save = async () => {
        console.log('userId:', userId)
        if (!userId) {
            setShowLogin(true);
            console.log('no user - showing login')
            return;
        }
        
        setInitialBooks(books);
        // upsert to database
        console.log('saving books:', books);
        const { data: bookData, error: bookError } = await supabase
            .from('books')
            .upsert(books)
            .select();
        if (bookError) {
            console.error('Error saving books:', bookError.message);
            return;
        }
        if (bookData) {
            console.log('Books saved:', bookData);
            if (!shelfId){
                console.log('creating shelf');
                const id = await createShelf();
                if (id) {
                    console.log('shelf id:', id);
                    setShelfId(id);
                    addBooksToShelf(id, bookData);
                }
            }
        }

        console.log('saved?');
        console.log('bookData:', bookData);

        

    };

    const closeModalAndSave = () => {
        setShowLogin(false);
        save();
    };

    const cancel = () => {
        setBooks(initialBooks);
        // probably will route back to previous page
    };

    useEffect(() => {
        // check if books is different from initialBooks
        if (JSON.stringify(books) !== JSON.stringify(initialBooks)) {
            setShowFooter(true);
        } else {
            setShowFooter(false);
        }
    }, [books, initialBooks]);

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <div className="create-shelf scene standard-width ">
                {/* <h1>Create a Shelf</h1> */}
                <div className='small-break'/>
                <Search add={add} />
                {books.length > 0 && 
                    <p className='help-text' style={{textAlign:'left'}}> 
                        Drag the icon to rearrange, or swipe left to delete!
                    </p>
                }
                <div className='small-break'/>
                {books.length > 0 && <div className='divider' />}
                <div className="shelf">
                    {books.map((book, index) => (
                        <DraggableBook
                            key={book.key}
                            index={index}
                            book={book}
                            moveBook={moveBook}
                            deleteBook={deleteBook}
                        />
                    ))}
                </div>
                <div className='small-break' />
                { books.length >= 4 &&
                    <div className='divider-no-padding' />
                }      
                <div className='small-break' />
                {showFooter &&
                    <div className='split-header'>
                        <button className='btn neutral' onClick={cancel} style={{width:'150px'}}>Cancel</button>
                        <button className='btn primary' onClick={save} style={{width:'150px'}}>Done</button>
                    </div>
                }
                {!showFooter && books.length > 0 &&
                    <div>
                        <div className='centered'>
                            <CopyLink link={`https://shelfiebooks.com/shelf/`+shelfId} />
                        </div>
                        <br />
                    </div>

                }
                {showLogin && (
                    <Modal close={() => setShowLogin(false)}>
                        <LoginForm onSuccess={closeModalAndSave} />
                    </Modal>
                )}
            </div>

        </DndProvider>
    );
};

export default CreateShelf;
