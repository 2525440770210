// Basic navbar component - just has a logo on one side

import React, {useEffect} from 'react';
import { useAuth } from '../resources/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const Navbar = () => {
    const { userId } = useAuth();
    const [showDropdown, setShowDropdown] = React.useState(false);

    // if path is /, then hide the create shelf button
    const path = window.location.pathname;

    // on click outside of the dropdown, close the dropdown
    useEffect(() => {
        const handleClick = (e) => {
            if (e.target.closest('.dropdown') || e.target.closest('.navbar-buttons')) {
                return;
            }
            setShowDropdown(false);
        };

        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, []);

    return (
        <nav className="navbar split-header">
            <div className="navbar-logo" onClick={() => window.location.href = userId ? `/user-shelf/${userId}` : '/'}>
                <img src="/shelfie.svg" alt="logo" />
            </div>

            <div className="navbar-buttons">
                {userId ? (
                    <button className='centered btn minimal' onClick={() => setShowDropdown(!showDropdown)}>
                        <MenuIcon sx={{ fontSize: 30 }} />
                    </button>
                ) : (
                    (path === '/' || path ==='/create') ?  null :
                        <a href='/create' className='link-no-fuss'>
                            <button className='centered btn brand'>
                                <PlaylistAddIcon/>
                                Create Shelf
                            </button>
                        </a>
                )}
                {!userId && (path ==='/create' || path==='/') ?  
                    <a href='/login' className='link-no-fuss'>
                        <button className='centered btn brand' style={{width:'80px'}}>
                            Login
                        </button>
                    </a> : null
                }
            </div>
            {showDropdown && (
                <div className="dropdown">
                    <button className="dropdown-item" onClick={() => window.location.href = '/create'}>Create Shelf</button>
                    <button className="dropdown-item" onClick={() => window.location.href = '/bookshelves'}>Manage Bookshelves</button>
                    <button className="dropdown-item" onClick={() => window.location.href = '/login'}>Account</button>
                </div>
            )}
        </nav>
    );
}

export default Navbar;


