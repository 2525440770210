import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Bookshelf from './scenes/Bookshelf';
import CreateShelf from './scenes/CreateShelf';
import { AuthProvider } from './resources/AuthContext';
import Login from './scenes/Login';
import MyBookshelves from './scenes/MyBookshelves';
import Landing from './scenes/Landing';

function App() {
  return (
    <div className="App">
        <AuthProvider>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/user-shelf/:owner" element={<Bookshelf type={'owner'}/>} />
                    <Route path="/shelf/:shelf" element={<Bookshelf type={'shelf'}/>} />
                    <Route path="/create" element={<CreateShelf />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/bookshelves" element={<MyBookshelves />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    </div>
  );
}

export default App;
