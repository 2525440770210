// Card with a link to a website
// Shows the logo, does not show text or a button

import React from 'react';

const LinkCard = ({ link, logo, style={}}) => {
    // if backgroundColor is passed in, use it. Otherwise, do not pass it in
    // if width is passed in, use it. Otherwise, do not pass it in
    return (
        <a href={link}
            target="_blank"
            rel="noreferrer"
            className="link-card">
            <img src={logo} alt="logo" style={style} />
        </a>
    );
}

export default LinkCard;


