// This is a component that takes in a 'bookshelf' with 'books', adds a header at the top, and then maps over the books to show each one.
// The header will be the 'name' of the bookshelf.
// The bookshelf also has a 'description' which should optionally be shown below the header.

import React from 'react';
import Book from './Book';
import { Dangerous } from '@mui/icons-material';

const Section = ({ section }) => {
    return (
        <div className="section">
            <h1 className='cutive-regular' style={{fontWeight:'800'}}>{section.name}</h1>
            {section.description && 
                <div className='description' dangerouslySetInnerHTML={{__html: section.description}} />
            }     
            <br/>           
            <div className="books">
                {section.books.map((book, index) => (
                    <Book key={index} book={book} />
                ))}
            </div>
        </div>
    );
}

export default Section;
