import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';

const CopyLink = ({ link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };

  return (
    <div className="copy-link-container">
      <IconButton onClick={handleCopy}>
        {copied ? <DownloadDoneIcon /> : <ContentCopyIcon />}
      </IconButton>
      <input type="text" value={link} readOnly className="link-input" />
    </div>
  );
};

export default CopyLink;
