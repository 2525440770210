import React, { useState } from 'react';
import BookLinks from './BookLinks';
import AddBook from './AddBook';

const Book = ({ book, showLinks=true, showAdd=false, addBook=()=>{} }) => {
    const [show, setShow] = useState(false);
    const isMobile = window.innerWidth < 768;
    
    const showHide = () => {
        if (!showLinks && !showAdd) return;
        setShow(!show);
    }
    
    return (
        <div className="book-container" style={{ position: 'relative' }}onMouseEnter={() => showHide()} onMouseLeave={() => showHide()}>
            <div className="book" onClick={showHide}>
                <img src={book.image} alt={book.title} />
                <div className="book-info">
                    <h2 className='cutive-regular'>{book.title}</h2>
                    <h3 style={{fontStyle: 'italic'}}>{book.author}</h3>
                    <p>{book.notes}</p>
                </div>
            </div>

            {show && 
                <div className='book-links-container show' onClick={showHide}>
                    <div>
                        {showLinks && <BookLinks book={book} />}
                        {!showLinks && showAdd && <AddBook book={book} addBook={addBook}/>}
                    </div>
                </div>
            }

        </div>
    );
}

export default Book;
