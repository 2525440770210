// just wraps LoginForm in a scene

import React, { useState } from 'react';

import LoginForm from '../components/LoginForm';

const Login = () => {
    const [show, setShow] = useState(false);

    return (
        <div className="scene centered standard-width">
            <LoginForm />
        </div>
    );
};

export default Login;