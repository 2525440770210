// Exportable object with methods to interact with Supabase

import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://iqusletqiskwsplcjrgc.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlxdXNsZXRxaXNrd3NwbGNqcmdjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTc2NTIwMDMsImV4cCI6MjAzMzIyODAwM30.D_WrnvDvJ2BzYpOpuD4C89d-sej5OPJCbVdtkxTrq6E'

const supabase = createClient(supabaseUrl, supabaseKey)

export default supabase
