// modal component: wraps around the modal content and provides the close button
// also has listeners that close the modal when the user clicks outside of the modal or presses the escape key
// takes in a show, setShow, and children prop

import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const Modal = ({ close, children }) => {
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                close();
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [close]);

    return (
        <div className='modal-container' onClick={close}>
            <div className="modal">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <button className="close-button" onClick={close}>
                        <CloseIcon />
                    </button>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;