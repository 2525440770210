import React, { useEffect, useState, useRef } from 'react';
import OpenLibrarySearch from '../resources/OpenLibrarySearch';
import Book from './Book';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

const Search = ({ add = () => {} }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const inputRef = useRef(null);

    const handleSearch = async () => {
        // Blur the input to close the mobile keyboard
        if (inputRef.current) {
            inputRef.current.blur();
        }
        if (searchTerm.trim() === '') return;
        setShow(true);
        setLoading(true);
        const data = await OpenLibrarySearch(searchTerm);
        setSearchResults(data.docs);
        setLoading(false);

        // Clear the input
        setSearchTerm('');
    };

    const addBook = (book) => {
        add(book);
        setShow(false);
    };

    // when click on modal backdrop, close modal
    const handleClick = (e) => {
        if (e.target.classList.contains('modal-backdrop')) {
            setShow(false);
        }
    };

    // close modal when escape is pressed
    const handleEscape = (e) => {
        if (e.key === 'Escape') {
            setShow(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    return (
        <div className="search">
            <div className="search-bar">
                <input
                    type="text"
                    ref={inputRef}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder='Add books to your shelf'
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
                <button onClick={handleSearch} className="search-button">
                    <SearchIcon style={{color:'#333' }} />
                </button>
            </div>
            {show && (
                <div className="search-results">
                    <div className="search-results-overlay">
                        {!loading &&
                            searchResults.map((book) => (
                                <Book
                                    key={book.key}
                                    book={book}
                                    addBook={addBook}
                                    showLinks={false}
                                    showAdd={true}
                                />
                            ))}
                        {loading && 
                            <div className='centered-centered' >
                                <CircularProgress />
                            </div>
                            }
                    </div>
                    <div className="modal-backdrop" />
                </div>
            )}
        </div>
    );
};

export default Search;
