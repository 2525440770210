// Component that displays the user's bookshelves
// Load in all the data for a bookshelf from supabase for that user ID
// Just show the name of the bookshelf and make it clickable

import { useEffect, useState } from 'react';
import {GetShelvesForOwner} from '../functions/GetShelves';
import { useAuth } from '../resources/AuthContext';

const MyBookshelves = () => {
    const [bookshelves, setBookshelves] = useState([]);
    const {userId} = useAuth();

    const fetchBookshelves = async () => {
        if (!userId) {
            return;
        }
        const data = await GetShelvesForOwner(userId);
        console.log(data);
        setBookshelves(data);
    }

    useEffect(() => {
        fetchBookshelves();
    }, [userId]);

    return (
        <div className='scene'>
            <div className='standard-width'>
                <h1>Your Bookshelves</h1>
                <ul>
                    {bookshelves.map((shelf) => (
                        <div className='shelf-card' key={shelf.id} onClick={() => window.location.href = `/shelf/${shelf.id}`} >
                            {shelf.name}
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default MyBookshelves;