// landing page component
// Also generate the CSS for me, copilot

// Marketing copy:
// "Easily share you favorite books."

// Shelfie allows you to easily share your favorite books in your Twitter bio, LinkedIn page, or anywhere else.
// Simply create a shelf, add your books, and share the link.

// Brand colors: #333, #000. #fff

// Logo: /shelfie.svg

import React from 'react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const Landing = () => {
    return (
        <div className="scene centered">
            <div className="landing no-scroll-x">
                {/* <h1 >shelfie</h1> */}
                <div className="standard-width centered landing-header-container">
                    <br />
                    <h1>Easily share your favorite books.</h1>
                    <br />
                </div>

                <button className="landing-button" onClick={() => window.location.href = '/create'}>Get Started</button>
                <br />
                <br />


                <div className='demo-container-container'>
                    <div className="demo-container centered standard-width">
                        <div className="marketing-copy" style={{paddingBottom:'0'}}>
                            <h2>The simple way to share book recommendations in your Twitter bio, on your LinkedIn, or anywhere else.</h2>
                        </div>
                        {/* <div className="marketing-copy" style={{paddingTop: '0', paddingBottom:'0'}}>
                            <h2>It's incredibly simple to get started:</h2>
                        </div> */}
                        <br />
                        <div className='numbered-header'>
                            <div className='circle-with-number'>
                                1
                            </div>
                            <h2>Create a shelf with your favorite books, or about a specific theme</h2>
                        </div>
                        <div className='small-break' />

                        <div className="demo-image-container centered">
                            <img className='demo-box' src="/shelfie-demo.png" alt="demo" />
                        </div>

                        <br />
                        <br />

                        <div className='numbered-header'>
                            <div className='circle-with-number'>
                                2
                            </div>
                            <h2>Share your shelf with the world</h2>
                        </div>
                        <div className='small-break' />


                        <div className="demo-image-container centered">
                            <div className='demo-box'> 
                                <InsertLinkIcon className='icon'/>
                                https://shelfiebooks.com/...
                            </div>
                        </div>
                        <br />
                        <br />
                        <h2>It's totally free, forever.</h2>
                        <br />
                        <h2>Get started in less than 5 minutes.</h2>
                        <br />
                        <br />

                        <button className="landing-button" onClick={() => window.location.href = '/create'}>
                            Create Shelf Now
                        </button>
                        <br />
                        <br />
                    </div>
                </div>

            </div>

            <div className="background-image">
                <img src="/shelfie-backdrop-med.jpeg" alt="logo" />
            </div>
        </div>
    );
}

export default Landing;


