// This is the core of the app. It takes in a param userId and fetches the user's bookshelves from the API. It then maps over the sections to show each one.

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Section from '../components/Section';
import { GetShelvesForOwner, GetShelf } from '../functions/GetShelves';

const Bookshelf = ({type}) => {
    const { owner, shelf } = useParams();

    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (!owner) {
            return;
        }
        console.log('owner:', owner)
        const fetchSections = async () => {
            const data = await GetShelvesForOwner(owner);
            setSections(data);
        };

        fetchSections();
    }, [owner]);

    useEffect(() => {
        if (!shelf) {
            return;
        }
        console.log('shelf:', shelf)
        const fetchSections = async () => {
            const data = await GetShelf(shelf);
            console.log('data:', data);
            setSections(data);
        };

        fetchSections();
    }, [shelf]);

    return (
        <div className="scene centered standard-width">
            <div className="bookshelf">
                {sections.map((section, index) => (
                    <Section key={index} section={section} />
                ))}
            </div>
        </div>
    );
}

export default Bookshelf;

