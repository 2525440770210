// https://openlibrary.org/search.json?q=the+lord+of+the+rings

// This provides a function that that takes in a search term and returns books in a nice clean format

const OpenLibrarySearch = async (searchTerm) => {
    const response = await fetch(`https://openlibrary.org/search.json?q=${searchTerm}&language:en&fields=title,author_name,cover_i,key,id_amazon`);
    const data = await response.json();

    // now, map over books and create a new object with the fields I want
    // image: 'https://covers.openlibrary.org/b/id/' + book.cover_i + '-L.jpg',
    // title: book.title, (make this title case)
    // author: book.author_name[0],

    data.docs = data.docs.map(book => {
        if (!book.cover_i || !book.author_name || !book.title || !book.key) {
            return null;
        }
        return {
            image: 'https://covers.openlibrary.org/b/id/' + book.cover_i + '-M.jpg',
            title: book.title.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
            author: book.author_name[0],
            key: book.key,
            id_amazon: book.id_amazon ? book.id_amazon : null
        }
    });

    // remove nulls
    data.docs = data.docs.filter(book => book !== null);

    return data;
}

export default OpenLibrarySearch;